export const Endpoints = {
  POST_LOGIN: "login",
  GET_FORGOT_PASSWORD: "forgot-password",
  GET_FORGOT_PASSWORD_USER_INFO: "tokens/:token",
  POST_RESET_PASSWORD: "reset-password",
  POST_LOGIN_HELP: "support/ticket",

  GET_ASSETS_FORMATS: 'assets/formats',
  GET_ASSETS_TYPES: 'assets/types',
  GET_ASSETS_GEOPATH_CLASIFICATIONS: 'assets/geopath-clasifications',
  GET_ASSETS_GEOLOCATIONS: 'assets/geolocations',
  GET_ASSETS: 'assets',
  GET_MENU: 'menus/post',
  GET_ASSETS_TEMPLATES_PDF: 'assets/templates/pdf',
  GET_ASSETS_EXPORT_XLS: 'assets/export',
  DELETE_ASSETS: 'assets',
  GET_ASSET: 'assets/:id'
}
