export const Constants = {
  GEOPATH_CLASIFICATION: {
    ESTABLISHMENT: 1,
    PUBLIC_ROAD: 2
  },
  MATERIAL: {
    DIGITAL: 11,
    PRINTED: 12
  }
}
