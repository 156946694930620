import AuthenticationType from "../../types/AuthenticationType";
import ActionType from "../../types/ActionType";
import {AUTHENTICATION_SET_DATA, AUTHENTICATION_SET_TOKEN} from "../constants/authentication";
import {getSession} from "../actions/authentication";

const initialState: AuthenticationType = {
  ...getSession()
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state: AuthenticationType = initialState, action: ActionType): AuthenticationType => {
  switch (action.type) {
    case AUTHENTICATION_SET_DATA:
      return {
        ...action.payload
      }
    case AUTHENTICATION_SET_TOKEN:
      return {
        ...state,
        token: action.payload.token
      }
    default:
      return state
  }
}
