import { HIDE_MODAL, SHOW_MODAL } from '../constants/show-modal'
import { ShowModalType } from '../../types/ShowModalType'
import ActionType from '../../types/ActionType'

const initialState: ShowModalType = {
  show: false,
  title: '',
  component: null,
  props: {}
}

export default (state = initialState, action: ActionType) => {
  switch (action.type) {
  case SHOW_MODAL:
    return {
      ...state,
      ...action.payload
    }
  case HIDE_MODAL:
    return {
      show: action.payload.show,
      component: null,
      title: '',
      props: {}
    }
  default:
    return state
  }
}
