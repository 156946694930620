import { AssetFiltersType } from '../../types'
import ActionType from '../../types/ActionType'
import { ASSET_FILTERS_VALUES_AND_TAGS } from '../constants/asset-filters'

export const initialState: AssetFiltersType = {
  values: {
    formatId: undefined,
    geopathClasificationId: undefined,
    code: undefined,
    otsMax: undefined,
    otsMin: undefined,
    priceFrom: undefined,
    priceTo: undefined,
    tradename: undefined,
    typeId: undefined,
  },
  tags: []
}

export default (state = initialState, action: ActionType) => {
  switch (action.type) {
  case ASSET_FILTERS_VALUES_AND_TAGS:
    return {
      ...action.payload
    }
  default:
    return state
  }
}
