import {combineReducers} from 'redux'
import menus from './menus'
import authentication from './authentication'
import proposalColumns from './proposal-columns'
import showDrawer from './show-drawer'
import showModal from './show-modal'
import assetFilters from './asset-filters'
import assetSelected from './asset-selected'
import assetShowing from './asset-showing'
import assetListOrMapType from './asset-list-or-map'

const reducers = {
  menus,
  authentication,
  showDrawer,
  showModal,
  proposalColumns,
  assetFilters,
  assetSelected,
  assetShowing,
  assetListOrMapType
}

export default combineReducers(reducers)
