import { ASSETS_SHOWING } from '../constants/asset-showing'

const values = {
  showing: 0,
  total: 0
}

// eslint-disable-next-line
export default (state = values, action: any = {}) => {
  switch(action.type) {
    case ASSETS_SHOWING:
      return {
        ...action.payload
      }
    default:
      return state
  }
}