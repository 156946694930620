import React, { Suspense } from "react"
import { BrowserRouter as Router, Switch } from "react-router-dom"
import Paths from "../config/Paths"
import ErrorBoundaryRoute from "./error-boundary-route"
import Loading from "../components/loading/Loading"

const Login = React.lazy(() => import("../modules/login/Login"))
const Logout = React.lazy(() => import("../modules/logout/Logout"))
const Dashboard = React.lazy(() => import("../modules/dashboard/Dashboard"))
const ForgotPassword = React.lazy(() => import("../modules/forgot-password/ForgotPassword"))
const NoMatch = React.lazy(() => import("../modules/no-match/NoMatch"))
const Error500 = React.lazy(() => import("../modules/error-500/Error500"))
const Forbidden = React.lazy(() => import("../modules/forbidden/Forbidden"))
const helpSupport = React.lazy(() => import("../modules/help-support/HelpSupport"))
const resetPassword = React.lazy(() => import("../modules/change-password/ChangePassword"))
const Assets = React.lazy(() => import("../modules/assets/Assets"))

const routes = [
  {
    path: Paths.LOGIN,
    component: Login,
  },
  {
    path: Paths.LOGOUT,
    component: Logout,
  },
  {
    path: Paths.FORGOT_PASSWORD,
    component: ForgotPassword,
  },
  {
    path: Paths.NO_MATCH,
    component: NoMatch,
  },
  {
    path: Paths.ERROR_500,
    component: Error500
  },
  {
    path: Paths.FORBIDDEN,
    component: Forbidden,
  },
  {
    path: Paths.LOGIN_HELP,
    component: helpSupport,
  },
  {
    path: Paths.INVENTORY_ASSETS,
    component: Assets,
  },
  {
    path: Paths.RESET_PASSWORD,
    component: resetPassword,
  },
  {
    path: Paths.DASHBOARD,
    component: Dashboard,
  },
  {
    path: '/*',
    component: NoMatch,
  },
]

const Routes = () => {
  const componentsRoutes: any[] = []

  const renderRoutes = (routes: any[], path: string) => {
    routes.forEach((route: any) => {
      if (route.routes) {
        renderRoutes(route.routes, route.path)
      }

      const routePath = (path + route.path).replace("//", "/")
      componentsRoutes.push(
        <ErrorBoundaryRoute
          key={componentsRoutes.length}
          path={routePath}
          component={route.component}
        />,
      )
    })
  }

  renderRoutes(routes, "")

  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Switch>{componentsRoutes}</Switch>
      </Suspense>
    </Router>
  )
}

export default Routes
