const Paths = {
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  DASHBOARD: "/dashboard",
  LOGOUT: "/logout",
  NO_MATCH: "/no-match",
  FORBIDDEN: "/forbidden",
  LOGIN_HELP: "/help-support",
  RESET_PASSWORD: "/reset-password/:token",
  ERROR_500: "/error",
  INVENTORY_ASSETS: '/inventory/assets',
}

export default Paths
