import ActionType from "../../types/ActionType";
import { MenusType } from '../../types'
import {MENU_SET_DATA, MENU_SPINNER, MENU_COLLAPSED} from "../constants/menu";

const initialState: MenusType = {
  spinner: false,
  loaded: false,
  collapsed: true,
  menus: []
}

export default (state: MenusType = initialState, action: ActionType): MenusType => {
  switch (action.type) {
    case MENU_SPINNER:
      return {
        ...state,
        spinner: action.payload.spinner
      }
    case MENU_COLLAPSED:
      return {
        ...state,
        collapsed: action.payload.collapsed
      }
    case MENU_SET_DATA:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
