import Paths from "./Paths"

export const Config = {
  END_POINT: "https://api.tolber.io/demo/",
  END_POINT_LOGIN: "https://oauth.dev.tolber.io/auth/realms/TDT/protocol/openid-connect/token",
  END_POINT_USER_INFO:
    "https://oauth.dev.tolber.io/auth/realms/TDT/protocol/openid-connect/userinfo",
  LOGIN_CLIENT_ID: "post",
  TDT_COMMONS: "http://100.25.133.67/api/",
  BASE_URL: "https://post.demo.tolber.io",
  PATH_DEFAULT: Paths.DASHBOARD,
  PER_PAGE: 15,
  MAP_BOX_STYLE: "",
  MAP_BOX_KEY: "pk.eyJ1IjoibWZlcm5hbmRlemJlcnRvbGEiLCJhIjoiY2tvajY5eDRnMHViZDJ2azJkNmo2N3VqNCJ9.JNvVN5GZjZecYoj09NPK6A",
  MAP_BOX_ZOOM: 12,
  TIME_REFRESH_NOTIFICATIONS: 30 * 1000, // segundos * milisegundos
  SEPARATOR: ".",
  SEP_DECIMAL: ",",
  HTTP_CODE_REFRESH_TOKEN: 407,
  MAPBOX_STYLE_EN: 'mapbox://styles/mfernandezbertola/ckrgvcc5140ml17okp4iwfkpd',
  MAPBOX_STYLE_ES: 'mapbox://styles/mfernandezbertola/cksot700c4zrr18louisxz8wg',
  MAPBOX_STYLE_PT: 'mapbox://styles/mfernandezbertola/cksot7y514zsk18lotghafqdt',
}
