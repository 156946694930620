import { HIDE_DRAWER, SHOW_DRAWER } from '../constants/show-drawer'
import { ShowDrawerType } from '../../types/ShowDrawerType'
import ActionType from '../../types/ActionType'

const initialState: ShowDrawerType = {
  show: false,
  placement: 'right',
  title: '',
  component: null,
  width: 300,
  props: {}
}

// eslint-disable-next-line
export default (state = initialState, action: ActionType) => {
  switch (action.type) {
  case SHOW_DRAWER:
    return {
      ...state,
      ...action.payload
    }
  case HIDE_DRAWER:
    return {
      ...state,
      show: action.payload.show,
      component: null,
      title: ''
    }
  default:
    return state
  }
}
