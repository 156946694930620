import { AssetListOrMapType } from '../../types'
import ActionType from '../../types/ActionType'
import { ASSET_LIST_OR_MAP } from '../constants/asset-list-or-map'

export const initialState: AssetListOrMapType = {
  showMap: false
}

export default (state = initialState, action: ActionType) => {
  switch (action.type) {
  case ASSET_LIST_OR_MAP:
    return {
      showMap: action.payload.showMap
    }
  default:
    return state
  }
}
