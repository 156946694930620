import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './assets/scss/style.scss'
import { Provider } from 'react-redux'
import App from './App'
import Store from './redux/store/Store'
import './i18n'
import Loading from './components/loading/Loading'

ReactDOM.render(
  <Provider store={Store}>
    <Suspense fallback={<Loading/>}>
      <App/>
    </Suspense>
  </Provider>,
  document.getElementById('root'),
)

// @ts-ignore
if (window.Cypress) {
  // @ts-ignore
  window.store = Store
}
