import AuthenticationType from "../../types/AuthenticationType";
import {AUTHENTICATION_SET_DATA, AUTHENTICATION_SET_TOKEN} from "../constants/authentication";
import SessionStorage from '../../commons/SessionStorage'
import LocalStorage from '../../commons/LocalStorage'
import moment from "moment";
import {Config} from "../../config";

export const authenticationSetData = (data: AuthenticationType) => {
  return {
    type: AUTHENTICATION_SET_DATA,
    payload: {...data},
  }
}

export const authenticationSetToken = (token: string) => {
  return {
    type: AUTHENTICATION_SET_TOKEN,
    payload: {token},
  }
}

type Data = {
  name: string
  token: string
  url: string
  expires_in: string
  refresh_expires_in: string
  refresh_token: string
  organization_id: number
}

export const setSession = (data: Data) => {
  LocalStorage.setItem(Config.LOGIN_CLIENT_ID + '_user_name', data.name)
  LocalStorage.setItem(Config.LOGIN_CLIENT_ID + '_user_token', data.token)
  LocalStorage.setItem(Config.LOGIN_CLIENT_ID + '_user_url', data.url ? data.url : '')
  LocalStorage.setItem(Config.LOGIN_CLIENT_ID + '_expires_in', data.expires_in)
  LocalStorage.setItem(Config.LOGIN_CLIENT_ID + '_refresh_expires_in', data.refresh_expires_in)
  LocalStorage.setItem(Config.LOGIN_CLIENT_ID + '_refresh_token', data.refresh_token)
  LocalStorage.setItem(Config.LOGIN_CLIENT_ID + '_expires_in_date', moment().add(data.expires_in, 'seconds').format('YYYY-MM-DD HH:mm:ss'))
  LocalStorage.setItem(Config.LOGIN_CLIENT_ID + '_organization_id', data.organization_id.toString())
}

export const setSessionRefreshToken = (data: any) => {
  LocalStorage.setItem(Config.LOGIN_CLIENT_ID + '_user_token', data.token)
  LocalStorage.setItem(Config.LOGIN_CLIENT_ID + '_expires_in', data.expires_in)
  LocalStorage.setItem(Config.LOGIN_CLIENT_ID + '_refresh_expires_in', data.refresh_expires_in)
  LocalStorage.setItem(Config.LOGIN_CLIENT_ID + '_refresh_token', data.refresh_token)
  LocalStorage.setItem(Config.LOGIN_CLIENT_ID + '_expires_in_date', moment().add(data.expires_in, 'seconds').format('YYYY-MM-DD HH:mm:ss'))
}

export const getRefreshToken = () => {
  const refreshToken = LocalStorage.getItem(Config.LOGIN_CLIENT_ID + '_refresh_token')
  if (refreshToken) return refreshToken

  return null
}


function buildSessionData(storage: any) {
  let name = storage.getItem(Config.LOGIN_CLIENT_ID + '_user_name')
  let access_token = storage.getItem(Config.LOGIN_CLIENT_ID + '_user_token')
  let avatar = storage.getItem(Config.LOGIN_CLIENT_ID + '_user_url')
  let organization_id = storage.getItem(Config.LOGIN_CLIENT_ID + '_organization_id')

  if (name && access_token) {
    return {
      isAuthenticated: true,
      account: {
        name: name,
        avatar,
        organization_id
      },
      token: access_token,
    }
  }

  return null
}

export const getSession = () => {
  let sessionData = buildSessionData(SessionStorage)
  if (sessionData) return sessionData

  sessionData = buildSessionData(LocalStorage)
  if (sessionData) return sessionData

  return {
    isAuthenticated: false,
    account: {
      name: '',
      avatar: '',
      organization_id: ''
    },
    token: '',
  }
}

export const clearSession = () => {
  LocalStorage.clearAll()
  SessionStorage.clearAll()
}
