import { ASSETS_SELECTED } from '../constants/asset-selected'

const ids: number[] = []

// eslint-disable-next-line
export default (state = { ids }, action: any = {}) => {
  switch (action.type) {
  case ASSETS_SELECTED:
    return {
      ...action.payload
    }
  default:
    return state
  }
}
