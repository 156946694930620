import React from 'react'
import Routes from './routes/Routes'
import es_ES from 'antd/lib/locale/es_ES'
import en_US from 'antd/lib/locale/en_US'
import pt_BR from 'antd/lib/locale/pt_BR'
import { useTranslation } from 'react-i18next'
import { ConfigProvider } from 'antd'

const App = () => {
  const { i18n } = useTranslation()

  const getAntLocae = () => {
    switch (i18n.language) {
    case 'es':
      return es_ES
    case 'en':
      return en_US
    case 'pt':
      return pt_BR
    default:
      return es_ES
    }
  }

  return <ConfigProvider locale={getAntLocae()}>
    <Routes/>
  </ConfigProvider>
}

export default App
